export default {
    setNotFoundPage({commit}, payload)
    {
        commit('setNotFoundPage', payload);
    },
    setClientSignatureEmail({commit}, payload)
    {
        commit('setClientSignatureEmail', payload);
    },
    setClientSignatureSurname({commit}, payload)
    {
        commit('setClientSignatureSurname', payload);
    },
    setClientSignatureName({commit}, payload)
    {
        commit('setClientSignatureName', payload);
    },
    setClientSignaturePatronymic({commit}, payload)
    {
        commit('setClientSignaturePatronymic', payload);
    },
    setClientSignaturePhoneNumber({commit}, payload)
    {
        commit('setClientSignaturePhoneNumber', payload);
    },
    setDocument({commit}, payload)
    {
        commit('setDocument', payload);
    },
    setClientSignatureSmsCode({commit}, payload)
    {
        commit('setClientSignatureSmsCode', payload);
    },
    setLinkExpired({commit}, payload)
    {
        commit('setLinkExpired', payload);
    },
    setSignCurrentTime({commit}, payload)
    {
        commit('setSignCurrentTime', payload);
    },
}
