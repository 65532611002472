<template>
  <div>
    <NotFoundPage />
  </div>
</template>

<script>
import NotFoundPage from "@/components/not-found-page/NotFoundPage";
export default {
name: "HomeComponent",
  components: {NotFoundPage},
  mounted() {
    const app = this;

    app.$store.dispatch('documentSignatureForm/setNotFoundPage', true);
  }
}
</script>

<style scoped>

</style>
