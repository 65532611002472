export default {
    setNotFoundPage(state, payload)
    {
        state.notFoundPage = payload;
    },
    setClientSignatureEmail(state, payload)
    {
        state.clientSignature.email = payload;
    },
    setClientSignatureSurname(state, payload)
    {
        state.clientSignature.surname = payload;
    },
    setClientSignatureName(state, payload)
    {
        state.clientSignature.name = payload;
    },
    setClientSignaturePatronymic(state, payload)
    {
        state.clientSignature.patronymic = payload;
    },
    setClientSignaturePhoneNumber(state, payload)
    {
        state.clientSignature.phone_number = payload;
    },
    setDocument(state, payload)
    {
        state.document = payload;
    },
    setClientSignatureSmsCode(state, payload)
    {
        state.clientSignature.sms_code = payload;
    },
    setLinkExpired(state, payload)
    {
        state.link_expired = payload;
    },
    setSignCurrentTime(state, payload)
    {
        state.signCurrentTime = payload;
    },
}
