<template>
    <div class="relative flex items-top justify-center min-h-screen bg-gray-100 dark:bg-gray-900 sm:items-center sm:pt-0 not-found-page">
        <div class="max-w-xl mx-auto sm:px-6 lg:px-8">
            <div class="flex items-center pt-8 sm:justify-start sm:pt-0">
                <div class="px-4 text-lg text-gray-500 border-r border-gray-400 tracking-wider">
                    404                    </div>

                <div class="ml-4 text-lg text-gray-500 uppercase tracking-wider">
                    Страница не найдена                   </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";

    export default {
        name: "NotFoundPage",
        computed: {
            ...mapGetters({
                getNotFoundPage: 'documentSignatureForm/getNotFoundPage',
            }),
        }
    }
</script>

<style scoped>
    .not-found-page {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 99999;
        top: 0;
        background: #ffffff;
    }
</style>
