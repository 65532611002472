export default {
    getNotFoundPage: (state) => {
        return state.notFoundPage;
    },
    getDocument: (state) => {
        return state.document;
    },
    getClientSignature: (state) => {
        return state.clientSignature;
    },
    getLinkExpired: (state) => {
        return state.link_expired;
    },
    getSignCurrentTime: (state) => {
        return state.signCurrentTime;
    },
}
