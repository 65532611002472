<template>
  <div id="app">
    <component :is="layout">
      <router-view/>
    </component>
  </div>
</template>

<script>
import AppLayout from "@/layouts/AppLayout/AppLayout.vue";
import EmptyLayout from "@/layouts/EmptyLayout/EmptyLayout.vue";

export default {
  computed: {
    layout()
    {
      return (this.$route.meta.layout || 'EmptyLayout');
    }
  },
  components: {
    AppLayout,
    EmptyLayout,
  }
}
</script>

<style>
  html {
    height: 100%;
  }

  body {
      background: url('../public/images/background.png');
      background-repeat: no-repeat;
      background-size: cover;
      background-attachment: fixed;
      padding-top: 30px;
      padding-bottom: 30px;
  }
</style>
