import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
    notFoundPage: false,
    document: null,
    clientSignature: {
        surname: null,
        name: null,
        patronymic: null,
        phone_number: null,
        email: null,
        sms_code: null,
    },
    link_expired: false,
    signCurrentTime: 60,
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
}
