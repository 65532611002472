import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeComponent from "@/components/home-component/HomeComponent.vue";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeComponent,
    meta: {layout: 'AppLayout'},
  },
  {
    path: '/home',
    name: 'home',
    component: HomeComponent,
    meta: {layout: 'AppLayout'},
  },
  {
    path: '/document/:link_hash',
    name: 'document',
    component: () => import('../components/document-signature-form/DocumentSignatureForm.vue'),
    meta: {layout: 'AppLayout'},
  },
  {
    path: '/info',
    name: 'info',
    component: () => import('../components/info/Info.vue'),
    meta: {layout: 'AppLayout'},
  },
  {
    path: '/404',
    name: '404',
    component: () => import('../components/not-found-page/NotFoundPage.vue'),
    meta: {layout: 'AppLayout'},
  },
  {
    path: '/privacy', component: () => import('../components/articles/Articles.vue'),
    name: 'privacy',
    meta: {guest: true, layout: 'GuestLayout'},
  },
  /*{
    path: '/about',
    name: 'about',
    meta: {layout: 'AppLayout'},
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/AboutView.vue')
  }*/
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
